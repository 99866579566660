import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://twitter.com/MakerDAO"
        }}>{`Twitter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.reddit.com/r/MakerDAO/"
        }}>{`Reddit`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://t.me/makerdaoOfficial"
        }}>{`Telegram`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://chat.makerdao.com/"
        }}>{`Chat`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.youtube.com/MakerDAO"
        }}>{`Youtube`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      